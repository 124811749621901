.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

.checkBox {
    width: 1vw;
    height:  1vw;
    margin-left: 0.5vw;

}

:global(.ltr) .checkBox {
    margin-left: initial;
    margin-right: 0.5vw;
}


@media (max-width: 480px) {
    .checkBox {
        width: 4vw;
        height:  4vw;
        margin-left: 2vw;
    }

    :global(.ltr) .checkBox {
        margin-left: initial;
        margin-right: 2vw;
    }

}