.rectangle2 {
    height: 5.85vh;
    background-color: var(--cardHeader);
}


.amountBox {
    background-color: var(--lightBlue);
}
.deniedBox {
    background-color: var(--red);
    color: var(--white);
}


.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;

}

.space {
    margin: 0 0.5vw 0 0;
}
:global(.ltr)  .space {
    margin: 0 0 0 0.5vw;
}

@media (max-width: 480px) {
    .rectangle2 {
        height: 6.5vh;
        background-color: var(--cardHeader);
    }
    .space {
        margin: 0 1vw 0 0;
    }
    :global(.ltr)  .space {
        margin: 0 0 0 1vw;
    }
}



