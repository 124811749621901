.logo {
    width: 25%;
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}


:global(.PhoneInputInput) {
    height: 6vh;
    border: 0.8px solid #E0E0E0;
    border-radius: 100px;
    font-size: 1.2rem;
    padding: 0 5vw;
    font-family: iranyekan, serif;
    /*direction: ltr;*/
}
:global(.ltr) :global(.PhoneInputInput) {
    font-family: 'Comfortaa', iranyekan, sans-serif !important;
    /*direction: ltr;*/
}

:global(.PhoneInputCountryIconImg) {
    width: 100%;
}



:global(.react-international-phone-input-container) {
    width: 100% !important;
}

:global(.react-international-phone-country-selector) {
    height: 5.5vh !important;
}
:global(.react-international-phone-country-selector-button) {
    height: 100% !important;
}
:global(.react-international-phone-input) {
    width: 100% !important;
    direction: ltr;
    height: 5.5vh !important;
    font-size: 1.1rem !important;
    font-family: iranyekan, serif !important;
    text-align: center !important;

}
:global(.ltr) :global(.react-international-phone-input) {
    font-family: 'Comfortaa', iranyekan, sans-serif !important;
}