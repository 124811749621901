.content {
   /* background-color: var(--lightBlueAlpha);*/

    max-width: 75%;
    word-break: break-word;
}

.container {
    /*background-color: #EFBE0F;*/

}

.icon {
    top: 5%;
    background-color: var(--white);
    width: 1.7vw;
    height: 1.7vw;
    border-radius: 100px;
    opacity: 0.8;
}

.leftIcon {
    left: 0;
}
:global(.ltr) .leftIcon {
    left: initial;
    right: 0;
}

.rightIcon {
    right: 0;
}
:global(.ltr) .rightIcon {
    right: initial;
    left: 0;
}

@media (max-width: 480px) {
    .icon {
        width: 7vw;
        height: 7vw;
    }
}
