.container {
    border-bottom: 1px solid #E0E0E0;

}

.container:last-child {
    border-bottom: initial;
}

.circle {
    width: 3.3vw;
    height: 3.3vw;
    border-radius: 100%;
    /*background-color: var(--cardHeader);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.rectangle {
    padding: 0 0.5vw;
}

.rate {
    margin-left: 1vw;
}

.spacing {
    padding: 0 0.25vw;
}

.name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}




@media (max-width: 480px) {

    .container {

    }

    .circle {
        width: 14vw;
        height: 14vw;
        border-radius: 100%;
        /*background-color: var(--cardHeader);*/

    }

    .rate {
        margin-left: 2vw;
    }

    .rectangle {
        padding: 0 2vw;
    }

    .spacing {
        padding: 0 1vw;
    }


}