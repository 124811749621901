.documentBox {
    border: 0.8px solid #E0E0E0;

}

.container {
    border: 0.8px solid #E0E0E0;
}
.header {
    background-color: var(--lightBlue);
    padding: 1.5vh 1vw;
    border-radius: 8px 8px 0 0;
}

.content {
    padding: 1.5vh 1vw;
}
.footer {
    border-top: 0.8px solid #E0E0E0;
    padding: 1.5vh 1vw;
}

.zoneBox {
    width: 100%;
    padding: 2vh 1vw;
    border-radius: 10px;
    border: 0.3vh dotted var(--cardBorder);
}

@media (max-width: 480px) {

    .header {
        padding: 1.5vh 4vw;
    }
    .content {
        padding: 1.5vh 4vw;
    }
    .footer {
        padding: 1.5vh 4vw;
    }



    .zoneBox {
        width: 100%;
        padding: 2vh 3vw;
        border-radius: 10px;
        border: 0.3vh dotted var(--cardBorder);
    }


}


