.container {
    border-bottom: 1px solid #E0E0E0;

}

.container:last-child {
    border-bottom: initial;
}

.circle {
    width: 3.3vw;
    height: 3.3vw;
    /*background-color: var(--cardHeader);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.rectangle {
    padding: 0 0.5vw;
}

.rate {
    margin-left: 1vw;
}

.name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    align-items: baseline;
}


@media screen and (min-width: 480px) and (max-width: 992px) {
    .container {

    }

    .circle {
        width: 6.8vw;
        height: 6.8vw;
        /*background-color: var(--cardHeader);*/

    }

    .rate {
        margin-left: 2vw;
    }

    .rectangle {
        padding: 0 1.2vw;
    }
}


@media (max-width: 480px) {

    .container {

    }

    .circle {
        width: 14vw;
        height: 14vw;
        /*background-color: var(--cardHeader);*/

    }

    .rate {
        margin-left: 2vw;
    }

    .rectangle {
        padding: 0 2vw;
    }


}