.container {
    border-bottom: 1px solid #E0E0E0;

}

.container:last-child {
    border-bottom: initial;
}

.disabled {
    color: var(--gray);
    opacity: 0.9;/*
    background-color: var(--lightGray)*/
}
.iconDisabled {
    filter: grayscale(0.75);
    opacity: 0.9;/*
    background-color: var(--lightGray)*/
}

.name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    align-items: baseline;
}

.circle {
    width: 3.3vw;
    height: 3.3vw;
    /*background-color: var(--cardHeader);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.rectangle {
    padding: 0 0.5vw;
}


.rate {
    margin-left: 1vw;
}

.symbol {
    margin-right: 0.25vw;
}
.ltr .symbol {
    margin-left: 0.25vw;
    margin-right: initial;
}

@media screen and (min-width: 480px) and (max-width: 992px) {
    .container {

    }

    .circle {
        width: 6.8vw;
        height: 6.8vw;
        /*background-color: var(--cardHeader);*/

    }

    .rate {
        margin-left: 2vw;
    }

    .rectangle {
        padding: 0 1.2vw;
    }


    .symbol {
        margin-right: 1vw;
    }
    .ltr .symbol {
        margin-left: 1vw;
        margin-right: initial;
    }
}


@media (max-width: 480px) {

    .container {

    }

    .circle {
        width: 14vw;
        height: 14vw;
        /*background-color: var(--cardHeader);*/

    }

    .rate {
        margin-left: 2vw;
    }

    .rectangle {
        padding: 0 2vw;
    }


    .symbol {
        margin-right: 1vw;
    }
    .ltr .symbol {
        margin-left: 1vw;
        margin-right: initial;
    }


}