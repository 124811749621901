.container {
    border: 1px solid #E0E0E0;
}

.header {
    background-color: var(--lightBlue);
    border-radius: 8px 8px 0 0;
}

/*.thisInput :global(.rmdp-container ) {
    width: 100%;
    height: 100%;
}*/


.circle {
    width: 3.3vw;
    height: 3.3vw;
    border-radius: 100%;
    background-color: var(--cardHeader);
}

.rectangle {
    height: 5.85vh;
    background-color: var(--cardHeader);
}

.subHeader {
    padding: 2vh 0.5vw 1vh;
    border-bottom: 1px solid #E0E0E0;
}


@media (max-width: 480px) {
    .circle {
        width: 14vw;
        height: 14vw;
        border-radius: 100%;
        background-color: var(--cardHeader);
    }

    .rectangle {
        height: 6.5vh;
        background-color: var(--cardHeader);
    }

    .subHeader {
        padding: 2vh 2vw 1vh;
    }
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

.disable:disabled,.button[disabled] {
    border: 0.3vh solid var(--cardHeader);
    background: var(--cardHeader);
    color: var(--textColor);
    cursor: not-allowed;
}

.thisButton:disabled,.button[disabled] {
    border: 0.3vh solid var(--cardHeader);
    background: var(--cardHeader);
    color: var(--textColor);
    cursor: not-allowed;
}