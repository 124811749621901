.asset {
    border: 1px solid #E0E0E0;
    margin: 2vh auto;
}

.logo {
    width: 3.1vw;
    height: 3.1vw;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.logo.grayFilter {
    -webkit-filter: grayscale(100%);
}

@media (max-width: 480px) {
    .logo {
        width: 14vw;
        height: 14vw;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}