.otpInputContainer {
    margin: 3vh auto 0;
}

.otpInputSeprator {
    padding: 0 0.40vw;
}

.otpInput {
    /*width: 3vw !important;
    height: 8vh;*/

    width: 2.5vw !important;
    height: 6.5vh;
    font-size: 1.3rem;
    font-family: iranyekan, serif;
    border-radius: 8px;
    border: 1px solid #3A3838C1;

}
:global(.ltr) .otpInput {
    font-family: 'Comfortaa', iranyekan, sans-serif !important;
}

@media screen and (min-width: 480px) and (max-width: 992px) {
    .otpInputContainer {
        margin: 2vh auto 0;
    }
    .otpInputSeprator {
        padding: 0 0.69vw;
    }

    .otpInput {
        /*width: 3vw !important;
        height: 8vh;*/

        width: 5vw !important;
        height: 6vh;
        font-size: 1.3rem;
        font-family: iranyekan, serif;
        border-radius: 8px;
        border: 1px solid #3A3838C1;

    }
}

@media (max-width: 480px) {

    .otpInputContainer {
        margin: 2vh auto 0;
    }
    .otpInputSeprator {
        padding: 0 1vw;
    }

    .otpInput {
        /*width: 3vw !important;
        height: 8vh;*/

        width: 11vw !important;
        height: 6.5vh;
        font-size: 1.3rem;
        font-family: iranyekan, serif;
        border-radius: 8px;
        border: 1px solid #3A3838C1;

    }
}