.title {
    margin-right: 0.5vw;
}

:global(.ltr) .title {
    margin-right: initial;
    margin-left: 0.5vw;
}

.nav {
    border-right: 2px dotted var(--gray);
    margin-right: 2.8%;
    padding-right: 2vw;
}

:global(.ltr) .nav {
    margin-right: initial;
    padding-right: initial;
    margin-left: 2.8%;
    padding-left: 2vw;
    border-right: initial;
    border-left: 2px dotted var(--gray);
}

.imageWrapper {
    height: 32vh;
    background-color: var(--gray);
    box-shadow: 0 9px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
}

.blankImage {
    width: 45%;
}

.image {
    box-shadow: 0 9px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
}

.thisIcon {
    top: 2.5%;
    background-color: var(--white);
    width: 1.7vw;
    height: 1.7vw;
    border-radius: 100px;
    opacity: 0.8;
    right: 4%;
}

:global(.ltr) .thisIcon {
    left: 4%;
    right: initial;
}

@media (max-width: 480px) {
    .nav {
        padding-right: 7vw;
    }

    :global(.ltr) .nav {
        padding-right: initial;
        padding-left: 7vw;
    }

    .title {
        margin-right: 2vw;
    }

    :global(.ltr) .title {
        margin-right: initial;
        margin-left: 2vw;
    }

    .thisIcon {
        width: 7vw;
        height: 7vw;
    }

}
