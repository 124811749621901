.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

.image {
    width: 20%;
}

.amountBox {
    background-color: var(--lightBlue);
}

.space {
    margin: 0 0.5vw 0 0;
}
:global(.ltr) .space {
    margin: 0 0 0 0.5vw;
}

@media (max-width: 480px) {
    .space {
        margin: 0 1vw 0 0;
    }
    :global(.ltr) .space {
        margin: 0 0 0 1vw;
    }
}

.thisButton {

    border-radius: 100px !important;

}

.background {
    background-color: var(--blue);
    color: var(--white);
    border: 1px solid var(--blue);
}

.border {
    background-color: var(--white);
    color: var(--textColor);
    border: 1px solid var(--blue);
}
