.container {
    background: var(--white);
}

.header {
    background-color: var(--lightBlue);
    border-radius: 8px;
}

/*.thisInput :global(.rmdp-container ) {
    width: 100%;
    height: 100%;
}*/

.active {
    background-color: var(--blue);
    color: var(--white);
    border: 1px solid var(--blue);
}
.item {
    border: 1px solid #E0E0E0;
    width: 50%;
    padding: 1vh 0;
    text-align: center;

}

.container a:first-child {
    border-radius: 0 100px 100px 0;
}

:global(.ltr) .container a:first-child {
    border-radius: 100px 0 0 100px;
}

.container.two a:nth-child(2n) {
    /*border-radius: 100px 0 0 100px;*/
}

.container a:nth-child(3n) {
    /*border-radius: 100px 0 0 100px;*/
}

.container a:last-child {
    border-radius: 100px 0 0 100px;
}

:global(.ltr) .container a:last-child {
    border-radius: 0 100px 100px 0;
}



.circle {
    width: 3.3vw;
    height: 3.3vw;
    border-radius: 100%;
    background-color: var(--cardHeader);
}

.rectangle {
    height: 5.85vh;
    background-color: var(--cardHeader);
}

.subHeader {
    padding: 2vh 0.5vw 1vh;
    border-bottom: 1px solid #E0E0E0;
}


@media (max-width: 480px) {
    .circle {
        width: 14vw;
        height: 14vw;
        border-radius: 100%;
        background-color: var(--cardHeader);
    }

    .rectangle {
        height: 6.5vh;
        background-color: var(--cardHeader);
    }

    .subHeader {
        padding: 2vh 2vw 1vh;
    }
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

.disable:disabled,.button[disabled] {
    border: 0.3vh solid var(--cardHeader);
    background: var(--cardHeader);
    color: var(--textColor);
    cursor: not-allowed;
}

.thisButton:disabled,.button[disabled] {
    border: 0.3vh solid var(--cardHeader);
    background: var(--cardHeader);
    color: var(--textColor);
    cursor: not-allowed;
}