.container {
    min-height: 100%;
}

.statusImg {
    width: 12%;
    /*
    filter: invert(99%) sepia(90%) saturate(2%) hue-rotate(154deg) brightness(110%) contrast(100%);
    */
}

.noteBox {
    background-color: var(--lightBlue);
    border: 2px solid var(--lightBlue);
}

.statusBox {
    padding: 1vh 1vw
}

.Blocked {
    /*background-color: var(--blackRed);*/
    border: 2px solid var(--blackRed);
    /*color: var(--white);*/
}

.Rejected {
    /*background-color: var(--blackRed);*/
    border: 2px solid var(--darkRed);
    /*color: var(--white);*/
}

.NoKYC {
    /*background-color: var(--orange);*/
    /*color: var(--orange);*/
    border: 2px solid var(--gray);
}
.Pending {
    /*background-color: var(--orange);*/
    /*color: var(--orange);*/
    border: 2px solid var(--blackOrange);
}

.Approved {
    /*background-color: var(--blackGreen);*/
    border: 2px solid var(--darkGreen);
    /*color: var(--white);*/
}

.thisButton {
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

.userFiles {
    width: 100% !important;
}

.wrapper {
    background-color: rgba(0, 0, 0, 0.6);
    height: 17%;
    bottom: 0px;
    border-radius: 8px 8px 0px 0px;
    /*filter: blur(4px);*/
    z-index: 2;
}



@media (max-width: 480px) {
    .statusBox {
        padding: 1vh 4vw
    }
}