.container {
    height: 100vh;
    /*background-color: #007bff;*/
}

.logo {
    width: 7%;
}

.thisButton {

    border-radius: 100px !important;
    width: 20%;

}

.background {
    background-color: var(--blue);
    color: var(--white);
    border: 1px solid var(--blue);
}

.border {
    background-color: var(--white);
    color: var(--textColor);
    border: 1px solid var(--blue);
}

@media (max-width: 480px) {

    .logo {
        width: 25%;
    }

    .thisButton {
        width: 80%;

    }


}