.zoneBox {
    width: 100%;
    padding: 2vh 3vw;
    border-radius: 10px;
    border: 0.3vh dotted var(--cardBorder);
}
.cancel {
    top: 1.2vh;
    left: 1.2vw;
}
.zone {
    padding: 2vh 2vw;
    height: 40vh;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-color: var(--cardHeader);
}




@media (max-width: 480px) {
    .zone {
        padding: 2vh 5vw;
        height: 40vh;
        border-radius: 10px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-color: var(--cardHeader);
    }

    .cancel {
        top: 1.2vh;
        left: 2.1vw;
    }
}
