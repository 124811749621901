.container {
    /*background-color: #fff;*/
    /*background-color: #e4f5a2;*/
}

.image {
    width: 15%;
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}


.dateDivider {
    margin: 2vh auto;
    text-align: center;
    background-color: var(--lightGray);
    border-radius: 100px;
}