.amountBox {
    background-color: var(--lightBlue);
}

.name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

.space {
    margin: 0 0.5vw 0 0;
}
:global(.ltr) .space {
    margin: 0 0 0 0.5vw;
}

@media (max-width: 480px) {
    .space {
        margin: 0 1vw 0 0;
    }
    :global(.ltr) .space {
        margin: 0 0 0 1vw;
    }
}