.container {
    border: 0.8px solid #E0E0E0;
    padding: 1.5vh 1vw;
}

.logo {
    width: 3.1vw;
    height: 3.1vw;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.logo.grayFilter {
    -webkit-filter: grayscale(100%);
}

@media (max-width: 480px) {
    .container {
        border: 0.8px solid #E0E0E0;
        padding: 1.5vh 4vw;
    }

    .logo {
        width: 12vw;
        height: 12vw;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--red);
    color: var(--white);
    border-radius: 100px !important;

}
