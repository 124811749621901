.container {
    min-height: 100%;
}
.loading {
    height: 6vh;
    background-color: var(--cardHeader);
}

.image {
    width: 20%;
}


.noteBox {
    background-color: var(--lightBlue);
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

.zoneBox {
    width: 100%;
    padding: 2vh 1vw;
    border-radius: 10px;
    border: 0.3vh dotted var(--cardBorder);
}


@media (max-width: 480px) {
    .zoneBox {
        width: 100%;
        padding: 2vh 3vw;
        border-radius: 10px;
        border: 0.3vh dotted var(--cardBorder);
    }
}