
.amountBox {
    background-color: var(--lightBlue);
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;

}

.name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    align-items: baseline;
}

.circle {
    width: 3.3vw;
    height: 3.3vw;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

@media screen and (min-width: 480px) and (max-width: 992px) {
    .circle {
        width: 6.8vw;
        height: 6.8vw;
    }
}

@media (max-width: 480px) {
    .circle {
        width: 14vw;
        height: 14vw;
    }
}