.container {
    min-height: 100%;
}

.noteBox {
    border: 2px solid var(--lightGray);
}

.noteBox:hover {
    background-color: var(--lightBlue);
}


.loading {
    height: 5.2vh;
    background-color: var(--cardHeader);
    border-radius: 100px;
}